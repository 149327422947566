import React, { forwardRef } from 'react'
import { LinkButton } from '../../buttons/Button'
import Project from '../../shared/Project'

const ProjectList = forwardRef((props, ref) => {
  const { landingPageProjects, projectsSection, location, currentButtons } = props
  const { moreButton, allProjectsButton } = currentButtons[0].node.frontmatter
  return (
    !!landingPageProjects.length && (
      <section ref={ref} className="container container-small mt-large">
        <h2 className="h3">{projectsSection.title}</h2>
        <div className="projectsBox">
          {landingPageProjects.map((project, i) => (
            <Project project={project} location={location} key={i} more={moreButton} />
          ))}
        </div>
        <div className="text-center">
          <LinkButton name={allProjectsButton} color="dark" link="/projects" />
        </div>
      </section>
    )
  )
})
export default ProjectList
