import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { LinkButton } from '../../buttons/Button'

export default function AboutUs({ description, heading, title, image, moreButton }) {
  return (
    <section className="container container-small mt-large padding-null">
      <h2 className="h3">{title}</h2>
      <div className="artBox">
        <div className="artBox-info">
          <h5 className="h5">{heading}</h5>
          <p>{description}</p>
          <LinkButton name={moreButton} color="dark" link="/aboutUs" />
        </div>
        <div className="artBox-imgWrap imageBox-desktop">
          <GatsbyImage image={getImage(image)} alt="AboutUs" />
        </div>
      </div>
    </section>
  )
}
