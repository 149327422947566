import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'

export default function WhyUs({
  completedObjects,
  description,
  meters,
  objects,
  squareMeters,
  whyWe,
  years,
  yearsOnTheMarket,
  background,
}) {
  return (
    <BgImage image={getImage(background)} className="mt-large imgCard">
      <div className="imgOverlay">
        <div className="container container-small ">
          <p className="h2">{description}</p>
          <div className="imgCard-box">
            <p className="h2">{whyWe}</p>
            <div>
              <div className="imgCard-list">
                <div className="imgCard-item">
                  <span>{years} +</span>
                  <span>{yearsOnTheMarket}</span>
                </div>
                <div className="imgCard-item">
                  <span>{objects}</span>
                  <span>{completedObjects}</span>
                </div>
              </div>
              <div className="imgCard-list">
                <div className="imgCard-item">
                  <span>{meters} k</span>
                  <span>{squareMeters}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  )
}
