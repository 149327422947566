import React from 'react'
import FooterHome from './FooterHome'
import HeaderHome from './HeaderHome'

export default function LayoutHome({ children, location, locale, locationPath, handleClickTop }) {
  return (
    <>
      <HeaderHome location={location} locale={locale} locationPath={locationPath} />
      {children}
      <FooterHome location={location} locale={locale} />
    </>
  )
}
