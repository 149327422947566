import React, { useState, useEffect } from 'react'
import HeaderContent from './shared/HeaderContent'

export default function HeaderHome({ location, locale, locationPath }) {
  const isHomePage = location.pathname === '/' || location.pathname === '/en/'
  const [header, setHeader] = useState(!isHomePage)

  const changeBackground = () => {
    setHeader(window.scrollY >= 10)
  }
  useEffect(() => {
    if (isHomePage) {
      window.addEventListener('scroll', changeBackground)
      return () => {
        window.removeEventListener('scroll', changeBackground)
      }
    }
  }, [isHomePage])

  return (
    <HeaderContent
      locale={locale}
      className={header ? 'header active' : 'header'}
      header={header}
      location={location}
      locationPath={locationPath}
    />
  )
}
