import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { showNumber } from '../../utils'

export default function Service({ service, idx }) {
  const { title, image, imageWide } = service.node.frontmatter
  return (
    <div className="servicesBox-item">
      <div className="imageBox-desktop">
        <GatsbyImage className="servicesBox-item" image={getImage(image)} alt="alt" />
      </div>
      <div className="imageBox-mobile">
        <GatsbyImage className="servicesBox-item" image={getImage(imageWide)} alt="alt" />
      </div>
      <div className="servicesBox-info">
        <div>
          <span className="servicesBox-number">[{showNumber(idx + 1)}]</span>
          <h5 className="h5">{title}</h5>
        </div>
      </div>
    </div>
  )
}
