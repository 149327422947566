import React from 'react'
import { showNumber } from '../../utils'

const Process = props => {
  const { process, i, toggle, selected } = props
  const { title, description } = process.node.frontmatter
  return (
    <div className={selected === i ? 'process-item open' : 'process-item'} key={i}>
      <div className="process-title" onClick={() => toggle(i)} role="presentation">
        <span className="process-counter">[{showNumber(i + 1)}]</span>
        <div className="d-flex process-info">
          <h4 className="h4">{title}</h4>
          <span className="process-icon"></span>
        </div>
      </div>
      <p className="process-text">{description}</p>
    </div>
  )
}

export default Process
