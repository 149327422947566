import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import LayoutHome from '../components/layout/LayoutHome'
import Hero from '../components/pages/index/Hero'
import ProjectList from '../components/pages/index/ProjectList'
import ServiceList from '../components/pages/index/ServiceList'
import ProcessList from '../components/pages/index/ProcessList'
import WhyUs from '../components/pages/index/WhyUs'
import Clients from '../components/pages/index/Clients'
import AboutUs from '../components/pages/index/AboutUs'
import Contact from '../components/pages/index/Contact'
import getItems from '../hooks/useLandingPageItemsQuery'
import { getItemsByLocale, filterById, setGalleryImages } from '../utils'
import { defaultLang } from '../config'

export default function IndexPage({ location, data, pageContext }) {
  const { allProjects, allProcesses, allServices, buttons } = getItems()
  const currentButtons = getItemsByLocale(pageContext.locale, buttons.edges)
  const bottom = useRef(null)
  const top = useRef(null)
  const handleClickBottom = () => {
    bottom.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleClickTop = () => {
    top.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const {
    heroSection,
    projectsSection,
    processesSection,
    servicesSection,
    achievementsSection,
    aboutUsSection,
    contactSection,
    customersSection,
  } = data.home.frontmatter
  const { customersSectionMedia } = data.customers.frontmatter
  const clients = {
    title: customersSection.title,
    customers: customersSectionMedia.customers,
  }
  const landingPageProjects = filterById(allProjects, projectsSection.projects)
  const landingPageServices = filterById(allServices, servicesSection.services).sortByNumber()
  const landingPageProcesses = filterById(allProcesses, processesSection.processes).sortByNumber()
  if (pageContext.locale !== defaultLang) {
    setGalleryImages(landingPageProjects, allProjects)
  }
  return (
    <LayoutHome
      location={location}
      locale={pageContext.locale}
      locationPath={pageContext.locationPath}
      handleClickTop={handleClickTop}
    >
      <Hero
        ref={top}
        {...heroSection}
        contactButton={currentButtons[0].node.frontmatter.contactButton}
        handleClickBottom={handleClickBottom}
      />
      <ProjectList
        ref={bottom}
        {...{ landingPageProjects, projectsSection, location, currentButtons }}
      />
      <ServiceList {...{ landingPageServices, servicesSection, currentButtons }} />
      <ProcessList
        {...{ landingPageProcesses, processesSection }}
        moreButton={currentButtons[0].node.frontmatter.moreButton}
      />
      <WhyUs {...achievementsSection} />
      <Clients {...clients} />
      <AboutUs {...aboutUsSection} moreButton={currentButtons[0].node.frontmatter.moreButton} />
      <Contact
        {...contactSection}
        contactButton={currentButtons[0].node.frontmatter.contactButton}
      />
    </LayoutHome>
  )
}

export const query = graphql`
  query ($id: String!, $defaultLocaleId: String!) {
    customers: markdownRemark(id: { eq: $defaultLocaleId }) {
      frontmatter {
        customersSectionMedia: customersSection {
          customers {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    home: markdownRemark(id: { eq: $id }) {
      frontmatter {
        customersSection {
          title
        }
        heroSection {
          description
          title
          vision
          background {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        projectsSection {
          projects
          title
        }
        processesSection {
          processes
          title
        }
        servicesSection {
          services
          title
        }
        achievementsSection {
          completedObjects
          description
          meters
          objects
          squareMeters
          whyWe
          years
          yearsOnTheMarket
          background {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        aboutUsSection {
          description
          heading
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          title
        }
        contactSection {
          background {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          title
        }
      }
      fields {
        slug
      }
    }
  }
`
