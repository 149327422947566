import React, { useState } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Process from '../../shared/Process'
import ArrowLight from '../../../images/assets/line-light.svg'
import ArrowLightShort from '../../../images/assets/line-light-short.svg'

export default function ProcessList({ landingPageProcesses, processesSection, moreButton }) {
  const [selected, setSelected] = useState(null)
  const toggle = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    !!landingPageProcesses.length && (
      <section className="container container-small mt-large">
        <h2 className="h3">{processesSection.title}</h2>
        <div className="process-list">
          {landingPageProcesses.map((proc, i) => (
            <Process key={i} toggle={toggle} process={proc} i={i} selected={selected} />
          ))}
        </div>
        <div className="text-center">
          <AnchorLink className="button btn-dark" to="/aboutUs#processes" title="Our team">
            <span>{moreButton}</span>
            <div className="arrowMobile">
              <ArrowLightShort />
            </div>
            <div className="arrowDesktop">
              <ArrowLight />
            </div>
          </AnchorLink>
        </div>
      </section>
    )
  )
}
