import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Button } from '../buttons/Button'
import { getSlug } from '../../utils'

export default function Project({ location, project, i, more }) {
  const { galleryImages, title, address, date } = project.node.frontmatter
  const backgroundImage = getImage(galleryImages[0])
  const slug = getSlug(project.node.fields.slug)
  return (
    <Link to={`${location.pathname}projects/${slug}`} key={i} className="projectsBox-item">
      <div className="projectsBox-imgWrap">
        <GatsbyImage className="projectsBox-imgWrap-img" image={backgroundImage} alt="alt" />
        <div className="projectsBox-overlay">
          <Button name={more} color="light" />
        </div>
      </div>
      <div className="projectsBox-info">
        <div className="d-flex">
          <h5 className="h5">{title}</h5>
          <span>{date}</span>
        </div>
        <p>{address}</p>
      </div>
    </Link>
  )
}
