import React from 'react'
import getFooter from '../../hooks/useFooterQuery'
import FooterMediaAndCopyrights from './shared/FooterMediaAndCopyrights'
import FooterProjectsList from './shared/FooterProjectsList'
import Logo from './shared/Logo'

export default function FooterHome({ location, locale }) {
  const { mediaList } = getFooter()
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-container">
          <div className="contactUs">
            <div className="logo">
              <Logo locale={locale} />
            </div>
          </div>
          <FooterProjectsList location={location} locale={locale} media={mediaList} />
        </div>
        <FooterMediaAndCopyrights
          locale={locale}
          media={mediaList}
          copyright={mediaList.edges[0].node.frontmatter.footer}
        />
      </div>
    </footer>
  )
}
