import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Button } from '../../buttons/Button'

const Contact = props => {
  const { title, background, contactButton } = props
  return (
    <BgImage image={getImage(background)}>
      <section className="bgImgSection imgOverlay d-flex mt-large">
        <div className="container">
          <h2 className="h3">{title}</h2>
          <AnchorLink to="/contacts#formDescription">
            <Button name={contactButton} color="light" link="/contacts" />
          </AnchorLink>
        </div>
      </section>
    </BgImage>
  )
}
export default Contact
