import React, { forwardRef } from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { LinkButton } from '../../buttons/Button'

const Hero = forwardRef((props, ref) => {
  const { description, title, vision, background, contactButton, handleClickBottom } = props
  return (
    <>
      <BgImage className="hero-bg" image={getImage(background)}>
        <section ref={ref} className="hero">
          <div className="container">
            <div className="hero-decorate">
              <span onClick={handleClickBottom} role="presentation">
                scroll
              </span>
            </div>
            <div className="hero-box">
              <span className="hero-preTitle">{vision}</span>
              <h1 className="h1">{title}</h1>
              <p className="hero-description">{description}</p>
              <LinkButton name={contactButton} color="light" link="/contacts" />
            </div>
          </div>
        </section>
      </BgImage>
      <div className="hero-description hero-movileDescr">{description}</div>
    </>
  )
})
export default Hero
