import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function BgColorSection({ customers, title }) {
  return (
    !!customers.length && (
      <section className="bgColorSection">
        <div className="container">
          <h2 className="h3">{title}</h2>
          <ul className="logoList">
            {customers.map(({ image }, i) => (
              <li key={i}>
                <GatsbyImage image={getImage(image.childImageSharp)} alt="Client" />
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  )
}
