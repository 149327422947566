import React from 'react'
import { LinkButton } from '../../buttons/Button'
import Service from '../../shared/Service'

export default function ServiceList({ landingPageServices, servicesSection, currentButtons }) {
  return (
    !!landingPageServices.length && (
      <section className="mt-large">
        <h2 className="h3">{servicesSection.title}</h2>
        <div className="servicesBox">
          {landingPageServices.map((service, i) => (
            <Service service={service} key={i} idx={i} />
          ))}
        </div>
        <div className="text-center">
          <LinkButton
            name={currentButtons[0].node.frontmatter.allServicesButton}
            color="dark"
            link="/services"
          />
        </div>
      </section>
    )
  )
}
